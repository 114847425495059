import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { DeleteFavouriteOffer } from "./DeleteFavouriteOffer";
import { CreateDeal } from "./CreateDeal";
import { ReplaceDeal } from "./ReplaceDeal";

export const FavouriteOffersActions = ({
  refetchFavOffers,
  favoriteOfferId,
  setSelectedStep,
  refetchTask,
  hasDeal,
  taskHasDeal,
  ableToCreateDeal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopPropagating = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div onClick={stopPropagating}>
      <Button
        id="change-status-button"
        aria-controls={open ? "change-status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="change-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {taskHasDeal ? (
          hasDeal ? (
            <CreateDeal
              refetchTask={refetchTask}
              favoriteOfferId={favoriteOfferId}
              setSelectedStep={setSelectedStep}
              hasDeal={hasDeal || !ableToCreateDeal}
            />
          ) : (
            <ReplaceDeal
              refetchTask={refetchTask}
              favoriteOfferId={favoriteOfferId}
              setSelectedStep={setSelectedStep}
            />
          )
        ) : (
          <CreateDeal
            refetchTask={refetchTask}
            favoriteOfferId={favoriteOfferId}
            setSelectedStep={setSelectedStep}
            hasDeal={hasDeal || !ableToCreateDeal}
          />
        )}

        {/* <MenuItem sx={{}}>{t("Download pdf file without details")}</MenuItem>
        <MenuItem sx={{}}>{t("Download pdf file wit details")}</MenuItem> */}
        <DeleteFavouriteOffer
          refetchFavOffers={refetchFavOffers}
          favoriteOfferId={favoriteOfferId}
        />
      </Menu>
    </div>
  );
};
