// ADMIN = 1, _("Admin")
// PROJECT_MANAGERS = 2, _("Project Managers")
// CUSTOMER_SERVICES = 3, _("Customer Services")
// NEGOTIATORS = 4, _("Negotiator")

export const rolesMap = {
  2: {
    initialUrl: "/app/project-manager/beneficiaries",
    queryVar: "PROJECT_MANAGERS",
    links: [
      { to: "/app/project-manager/beneficiaries", text: "Beneficiaries" },
      { to: "/app/project-manager/orders", text: "Orders" },
      { to: "/app/project-manager/customer-service", text: "Customer Service" },
      { to: "/app/project-manager/negotiators", text: "Negotiators" },
    ],
  },
  3: {
    initialUrl: "/app/customer-service/tasks",
    queryVar: "CUSTOMER_SERVICES",
    links: [{ to: "/app/customer-service/tasks", text: "Tasks" }],
  },
  4: {
    initialUrl: "/app/negotiator/tasks",
    queryVar: "NEGOTIATORS",
    links: [{ to: "/app/negotiator/tasks", text: "Tasks" }],
  },
};

// IN_PROGRESS = 1, _("In Progress")
// REJECTED = 3, _("Rejected")
// PENDING_REVIEW = 4, _("Pending Review")
// FINISHED = 5, _("Finished")
// CHECK_BENEFICIARY_DATA = 6, _("Checking Beneficiary Data")
// REVIEW_RECOMMENDATIONS = 7, _("Review Recommendations")
// REVIEW_OFFERS = 8, _("Review Offers")
// CREATE_DEAL = 9, _("Create Deal")

// (NOT_ASSIGNED = 1), _("Not Assigned");
// (ASSIGNED_TO_CUSTOMER_SERVICE = 2), _("Assigned To Customer Service");
// (ASSIGNED_TO_NEGOTIATOR = 3), _("Assigned To Negotiator");
// (ASSIGNED_TO_PROJECT_MANAGER = 5), _("Assigned To Project Manager");

export const statusColorMap = {
  "1": { color: "#4A3F35", background: "#F5E8D7" },
  "3": { color: "#3C2A66", background: "#E8E0F7" },
  "4": { color: "#1F5132", background: "#D9F1E1" },
  "5": { color: "#6B4423", background: "#FBEAD7" },
  "6": { color: "#1E5F9E", background: "#E1F1FF" },
  "7": { color: "#1E5F9E", background: "#E1F1FF" },
  "8": { color: "#1E5F9E", background: "#E1F1FF" },
  "9": { color: "#1F5132", background: "#D9F1E1" },
  "10": { color: "#83243D", background: "#FDE3E8" },
  "11": { color: "#3A4568", background: "#E3E8F1" },
  "12": { color: "#556A23", background: "#EDF7DC" },
  "13": { color: "#A5522F", background: "#FCE9DB" },
  "14": { color: "#365353", background: "#E3ECEB" },
  "15": { color: "#7A3D99", background: "#F2E4FF" },
  "16": { color: "#7A1F1F", background: "#FDE3E3" },
  "17": { color: "#44592A", background: "#E8F3D5" },
  "18": { color: "#4F2623", background: "#FBD9D9" },
  "19": { color: "#2F4726", background: "#E2F2C4" },
  "20": { color: "#333333", background: "#F0F0F0" },
};

export const isProjectManager = (userInfo) => {
  return userInfo?.role?.key === 2;
};

export const isCustomerService = (userInfo) => {
  return userInfo?.role?.key === 3;
};

export const isNegotiator = (userInfo) => {
  return userInfo?.role?.key === 4;
};
